import { useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { 
  MainContainer, 
  ChatContainer, 
  MessageList, 
  MessageInput, 
  Message, 
  TypingIndicator 
} from "@chatscope/chat-ui-kit-react";
import axios from 'axios';

const messagesTest = [
  { role: "assistant", text: "Welcome to SamAI!  I provide information about Samuel Dove's professional experience and qualifications as a software engineer, tailored to assist recruiters and developers in evaluating him as a candidate for software engineering positions." },
];

const ResumeAI = () => {
  const [apiMessages, setApiMessages] = useState(messagesTest);
  const [isTyping, setIsTyping] = useState(false);
  const [inputEnabled, setInputEnabled] = useState(true);
  const [threadId, setThreadId] = useState(null)

  const sendMessage = (message_content) => {

    const newMessage = { role: "user", text: message_content };
    const newApiMessages = [...apiMessages, newMessage]
    setApiMessages(newApiMessages)
    setIsTyping(true);
    setInputEnabled(false); // Disable input while AI is typing

    const url = `https://qjwgixlhls4cj4lqyq2hf6iyoy0fnzje.lambda-url.us-east-2.on.aws/message?ai_name=sam`+ (threadId === null ? '' : `&thread_id=${threadId}`);
    axios({
      method: 'post',
      url: url,
      data: {
        message_text: message_content
      },
      headers: {
        "Content-Type": "application/json",
        "accept": 'application/json'
      }
      }).then((res) => {
        setThreadId(res.data.thread_id)
        setApiMessages([
          ...newApiMessages,
          {'text': res.data.messages[0].text,
            'role': 'assistant'
          },
        ])
        setIsTyping(false);
        setInputEnabled(true);
      });
  };

  return (
    <div>
      <MainContainer>
        <ChatContainer>
          <MessageList 
            typingIndicator={isTyping ? <TypingIndicator content="AI is thinking...this make take a bit" /> : null}
          >
            {apiMessages.map((message, index) => (
              <Message 
                key={index} 
                model={{
                  message: message.text,
                  sender: message.role,
                  direction: message.role === "assistant" ? "incoming" : "outgoing",
                  position: "single",
                }} 
              />
            ))}
          </MessageList>
          <MessageInput 
            placeholder="Type message here" 
            attachButton={false} 
            onSend={sendMessage} 
            disabled={!inputEnabled}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default ResumeAI;
